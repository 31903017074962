.Middle-section {
    display: grid;
    grid-template-columns: 1fr minmax(.1em, 10em) minmax(12em, 68.5em) minmax(.2em, 7em) 1fr;
    grid-template-rows: 2rem 8.4em 26em 13em auto 6em 10rem;

    background: var(--secondary);
    overflow: hidden;
}

.phone-image {
    justify-self: end;
    grid-column: 3/5;
    grid-row: 2/5;
    max-width: 75%;
    z-index: 1;
}

.cloud-image-left {
    grid-column: 2/4;
    grid-row: 3;
    justify-self: left;
    margin-left: -15em;
    margin-top: 15em;
    filter: drop-shadow(2px 4px 30px rgba(184, 184, 184, 0.3));
    width: clamp(60rem, 10vw, 100%);

}

.cloud-image-right {
    grid-column: 3/6;
    grid-row: 6;
    justify-self: right;
    filter: drop-shadow(2px 4px 30px rgba(184, 184, 184, 0.3));
    width: clamp(60rem, 8vw, 100%);
    z-index: 0;
    margin-top: -16em;
}


.rotate-text {
    grid-column: 3/4;
    grid-row: 2;
    justify-self: start;
    margin-left: 5rem;
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.3));
}

.Middle-section-bluebox {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    background-color: var(--primary);
    border-radius: 6rem;
    display: grid;
    grid-template-columns: minmax(.2em, 5.7em) minmax(15em, 35.5em) minmax(.2em, 5.7em) 1fr;
    grid-template-rows: minmax(.2em, 1fr) minmax(20em, 1fr) minmax(.7em, 1fr);
}

.heading-bluebox {
    font-family: var(--ff-primary);
    font-size: clamp(1.6rem, 3vw, 2.2rem);
    font-style: normal;
    margin-bottom: 0.3em;
}

.Middle-section-bluebox-text {
    grid-column: 2;
    grid-row: 2/3;
    align-self: end;
}


.download {
    grid-column: 3 / 4;
    grid-row: 5;
    align-self: center;
    justify-self: center;
    font-size: clamp(1rem, 7vw, 9rem);
    letter-spacing: 1.8rem;
    z-index: 1;
    text-decoration: none;
    font-family: var(--ff-headings);
    font-style: italic;

}

.download:hover {
    color: var(--quinary);
}

.download-text {
    grid-column: 3 / 4;
    grid-row: 6;
    align-self: flex-start;
    z-index: 1;
}


@media (min-width: 2800px) {
    .cloud-image-right {
        justify-self: center;
    }

}


@media (max-width: 900px) {
    .Middle-section-bluebox {
        grid-column: 2/5;
        margin: 0 1em;
    }

    .cloud-image-left {
        grid-column: 2/4;
        grid-row: 4;
        justify-self: left;
        margin-left: 1em;
        margin-top: 0em;
    }

    .cloud-image-right {
        grid-column: 2/5;
        grid-row: 4;
        justify-self: right;
        margin-top: 0em;
    }

    .download {
        grid-row: 4;
        margin-top: 1em;
        letter-spacing: 1.4rem;
    }

    .download-text {
        justify-self: center;
        align-self: center;
        text-align: center;

    }

    .Middle-section {
        grid-template-rows: 2rem 8.4em 28em 13em auto 6em 7rem;
    }

}

@media (max-width: 400px) {
    .download {
        grid-row: 4;
        margin-top: 2em;
    }

    .Middle-section {
        grid-template-rows: 2rem 8.4em 28em 13em auto 6em 5rem;
    }
}