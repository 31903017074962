@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;1,900&family=Open+Sans:wght@400;700&display=swap');

:root {
  --primary: rgb(0, 158, 224);
  --secondary: rgb(252, 206, 180);
  --tertiary: rgb(0, 0, 0);
  --quaternary: rgb(0, 80, 116);
  --quinary: rgb(255, 255, 255);
  --ff-primary: 'Open Sans', sans-serif;
  --ff-headings: 'Merriweather', serif;

  --fw-regular: 400;
  --fw-medium: 500;
  --fw-bold: 700;
  --fw-heading-regular: 400;
  --fw-heading-medium: 500;
  --fw-heading-bold: 900;

  --fs-regular: 1.2rem;
  --fs-medium: 1.4rem;
  --fs-bold: 2.2rem;
  --fs-heading-regular: 1.6rem;
  --fs-heading-medium: 2.8rem;
  --fs-heading-bold: 4.2rem;


}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}


/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


/* utility classes */
h4 {
  font-size: clamp(1rem, 3vw, var(--fs-heading-regular));
  font-weight: var(--fw-heading-regular);
  font-family: var(--ff-headings);
  color: var(--primary);
  font-style: italic;
}

h3 {
  font-size: clamp(1rem, 5vw, var(--fs-heading-regular));
  font-weight: var(--fw-heading-regular);
  font-family: var(--ff-headings);
  color: var(--primary);
  font-style: italic;
}

h2 {
  font-size: clamp(1rem, 5vw, var(--fs-heading-medium));
  font-weight: var(--fw-heading-medium);
  font-family: var(--ff-headings);
}

h1 {
  font-size: clamp(1rem, 11vw, var(--fs-heading-bold));
  font-weight: var(--fw-heading-bold);
  font-family: var(--ff-headings);
  color: var(--primary);
}

p {
  font-size: clamp(1rem, 4vw, var(--fs-regular));
  font-weight: var(--fw-regular);
  font-family: var(--ff-primary);
  color: var(--quinary);
}

.bold {
  font-weight: var(--fw-bold);
}

.bg-primary {
  background: var(--primary);
}

.bg-secondary {
  background: var(--secondary);
}

.color-primary {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.color-darkblue {
  color: var(--quaternary);
}

.color-white {
  color: var(--quinary);
}

.container {
  --max-width: 1280px;
  --padding: 1rem;

  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
}


.flex-justify-center {
  display: flex;
  justify-content: center;
}


/* general styles */


.App {
  height: 100%;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  scroll-behavior: smooth;

}

.Hero-section {
  background:
    radial-gradient(circle at center,
      rgba(165, 186, 209, 1), rgba(255, 0, 0, 0) 40%),
    radial-gradient(circle at 25% 90%,
      rgba(252, 205, 180, 1), rgba(0, 255, 0, 0) 25%),
    radial-gradient(circle at 70% 25%,
      rgba(236, 209, 205, 1), rgba(0, 255, 0, 0) 35%),
    #DADCD7;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* paralliar effect */

}

.Hero-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* hight content plus 20rem */
  height: fit-content;
  background:
    url(./assets/cloud_oben.png),
    url(./assets/cloud_unten.png);
  background-repeat: repeat-x;
  background-position:
    0px 0px, 800px bottom;
  background-size: clamp(100rem, 100vw, 160rem), clamp(100rem, 100vw, 130rem);
  animation-name: Animation1, Animation2;
  animation-iteration-count: 1, infinite;
  animation-duration: 60s, 60s;
  animation-timing-function: ease-out, ease-in-out;
  animation-direction: alternate, alternate;
  background-attachment: fixed, fixed;

  /* create parallex effect */
}

.Share-and-download-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Hero-section-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes Animation1 {
  100% {
    background-position: -1200px 0px, 500px bottom;
  }
}

@keyframes Animation2 {
  100% {
    background-position: -1200px 0px, 500px bottom;
  }
}

@media (prefers-reduced-motion: reduce) {
  .Name-content {
    animation: none;
  }
}

.amplify-button[data-variation='primary'] {
  background: var(--primary);
}

.amplify-button[data-variation='primary']:hover {
  background: var(--quaternary);
}

[data-amplify-authenticator] [data-amplify-footer] {
  padding-bottom: var(--amplify-components-authenticator-footer-padding-bottom);
  text-align: center;
  display: none;
}