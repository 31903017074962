.Contact-section {
    display: grid;
    grid-template-columns: minmax(1em, 1fr) minmax(1em, 23em) minmax(1em, 25em) minmax(1em, 23em) minmax(1em, 1fr);
    grid-template-rows: 5.3rem auto 2.5rem 2.8rem;

}

.Write-me-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
    align-items: center;
    padding: 2rem;
    grid-column: 2;
    grid-row: 2/4;
    background-color: var(--secondary);
    min-height: 27.8rem;
    width: 100%;
    border-radius: 4.3rem;
    text-align: center;
}

.Contact-btn-container {
    color: var(--quinary);
    height: clamp(25px, 6vw, 80px);
}

.write-me-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2em;
}

.contact-info {
    display: grid;
}

.Contact-info-container {
    grid-column: 4;
    grid-row: 2/4;
    justify-self: end;
    align-self: end;
    z-index: 2;
}

.contact-heading {
    font-family: var(--ff-regular);
    font-size: clamp(1.2rem, 3vw, 1.8rem);
    font-weight: var(--fw-bold);
}


.contact-image {
    grid-column: 3/5;
    grid-row: 2;
    justify-self: center;
    align-self: baseline;
    z-index: 0;
    width: clamp(70%, 4vw, 70%);
}



@media (max-width: 820px) {

    .Write-me-container {
        grid-column: 2/5;
        grid-row: 2;
        background-color: var(--primary);
        width: 100%;
        text-align: center;
        justify-content: start;
        min-height: auto;
    }

    .Contact-info-container {
        grid-column: 2/5;
        grid-row: 3;
        justify-self: center;
        align-self: flex-start;
        z-index: 2;
        text-align: center;
        padding-bottom: 1rem;
    }

    .contact-image {
        grid-column: 2;
        grid-row: 1;
        justify-self: center;
        align-self: flex-start;
        z-index: 0;
        margin-top: 1rem;
    }


    .Contact-section {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto auto;
    }

    .write-me-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 2em;

    }

    .Contact-section {
        padding-top: 2rem;
        padding-bottom: 2rem;

    }

}