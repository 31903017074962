/* sharebutton component start */

.share-container {
    color: var(--quinary);
    font-family: var(--ff-primary);
    font-size: var(--fs-regular);
    font-weight: var(--fw-bold);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto 0.6rem auto auto;
}

.nameit-image {
    grid-column: 2;
    grid-row: 2/4;
    justify-self: center;
    align-self: center;
    height: clamp(35px, 6vw, 100%);
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.3));
}

.share-text {
    grid-column: 1;
    grid-row: 2;
    align-self: end;
    font-family: var(--ff-primary);
    font-size: clamp(0.9rem, 3vw, 1.2rem);
    font-weight: var(--fw-bold);
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.download-image-text {
    grid-column: 3;
    grid-row: 2;
    align-self: end;
    justify-self: end;
    font-family: var(--ff-primary);
    font-size: clamp(0.9rem, 3vw, 1.2rem);
    font-weight: var(--fw-bold);
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.share-buttons {
    grid-column: 1;
    grid-row: 4;
}

.smedia-share-btn {
    gap: 0.5em;
    color: var(--quinary);
    height: clamp(2rem, 6vw, 3.2rem);
    margin-bottom: 1rem;
    padding-right: 0.5em;
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.3));
}

.smedia-share-btn:hover {
    scale: 1.2;
}


.Download-Image {
    grid-column: 3;
    grid-row: 4;
    justify-self: end;
    align-self: flex-start;
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.3));
    cursor: pointer;
}

@media (max-width: 420px) {
    .nameit-image {
        grid-column: 1/4;
        grid-row: 1;
        justify-self: center;
    }

    .share-container {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto 0.6rem auto auto;
    }
}