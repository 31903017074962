.footer {
    background-color: #f5f5f5;

}

.footer-container {
    display: flex;
    min-height: 7.6rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
}


.link-text {
    color: var(--quaternary);
    text-decoration: none;
    font-family: var(--ff-primary);
    font-size: calc(var(--fs-regular) / 1.4);
}

a:-webkit-any-link {
    color: var(--primary);
    text-decoration: none;
}

@media (max-width: 420px) {
    .footer-container {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}