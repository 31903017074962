.name-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-top: 4.6rem;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form--input--firstname::placeholder,
.form--input--lastname::placeholder {
    color: var(--primary);
}

.form--input,
.form--input--firstname,
.form--input--lastname {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    font-style: italic;
    color: var(--primary);
}


.form--select--button {
    display: grid;
    grid-template-columns: minmax(1rem, 13rem) 1fr minmax(1rem, 13rem);
    align-items: center;
    justify-items: flex-end;

}

.form--select,
.form--selector {
    display: flex;
    grid-column: 2/3;
    align-items: center;
    justify-self: flex-end;
    gap: 0.2em;
    background: transparent;
    border: none;
    outline: none;
    font-size: clamp(1rem, 5vw, var(--fs-heading-regular));
    font-weight: var(--fw-heading-regular);
    font-family: var(--ff-headings);
    color: var(--primary);
    font-style: italic;
}


.form--selector {
    cursor: pointer;


}

.form--selector option {
    cursor: pointer;
    background: #C1C9D2;
    color: var(--primary);
    font-size: clamp(1rem, 3vw, var(--fs-medium));
}

.form--button {
    border: none;
    background: transparent;
    grid-column: 3;
    z-index: 0;
    margin-top: -50px;
}

.form-button-image {
    cursor: pointer;
    justify-content: center;
    align-self: flex-end;
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.3));
}

.form-button-image:active {
    outline: none;
    border: none;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
}

.form-button:active {
    outline: none;
    border: none;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
}

.output {
    min-height: 12.6rem;
}

.Name-output,
.Name-output {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;

}

.explainer {
    display: flex;
    justify-content: center;
    justify-self: flex-end;
    align-self: flex-end;
    max-width: 793px;
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 2rem;

}

.first-step-a {
    display: none;

}


/* media queries */

@media (max-width: 800px) {

    .form {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .form--select,
    .form--selector {
        grid-column: 1/4;
        align-self: center;
        justify-self: center;

    }

    .form--select--button {
        flex-flow: column;
        flex-direction: column;
    }

    .form-button-image {
        cursor: pointer;
        max-width: 8rem;

    }

    .form--button {
        grid-column: 1/4;
        margin-top: 0px;
        justify-self: center;
    }

    .explainer {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

}